.HomePage {
    text-align: center;
}

.HomePageList {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

#homeFilterBar .listFilter {
    margin-top: 10px;
}

#homeFilterBar {
    overflow: hidden;
    background-color: #00CCF0;
    border-radius: 15px;
    padding: 5px;
    transition: 1s;
}

@media screen and (min-width: 490px) {
    #homeFilterBar {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    #homeFilterBar .listFilter {
        margin-top: 0;
    }
    
  }