.AddBookForm {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    background-color: #CC8B56;
    border: 0;
    text-decoration: none;
    box-shadow: 0px 11px 21px -8px rgba(0, 0, 0, 0.5);
    margin-bottom: 40px;
    border-radius: 10px;
    padding: 30px;
}