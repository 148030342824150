.UserHomeItem {
    background-color: #CC8B56;
    display: flex;
    flex-direction: column;
    border: 0;
    text-decoration: none;
    box-shadow: 0px 11px 21px -8px rgba(0, 0, 0, 0.5);
    margin-bottom: 40px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 10px;
    padding: 5px
  }
  
  .UserHomeItem:hover {
    cursor: pointer;
  }
  
  .UserHomeItem_info,
  .UserHomeItem_info:visited {
    color: black;
  }
  
  .UserHomeItem_image {
    height: 256px;
    width: 160px;
    border-radius: 10px 10px 0 0;
    background-size: cover;
    background-position: 50% 50%;
  }
  
  .UserHomeItem_details {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
  }
  
  .UserHomeItem_details h3 {
    margin: 0;
  }
  
  .UserHomeItem_details span {
    display: block;
    text-align: center;
  }
  