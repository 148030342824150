* {
    box-sizing: border-box;
}

body {
    font-size: 17px;
    background-color: #E4F6F9;
    height: 100%;
}
  
  .AppHeader {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .AppMain {
    margin-top: 13vh;
  }
