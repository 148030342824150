#Header {
    height: 10vh;
    display: flex;
    align-items: normal;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    transition: .7s all;
    margin-left: -10px;
  }

  .changeColor{
    background: #00CCF0;
  }

.Header_image {
  height: 35px;
  margin-top: 6px;
  padding-left: 10px;
}
  
  .center-nav {
    padding-top: 10px;
    margin-left: 20px;
  }
  
  .Header a {
    color: #444;
    text-decoration: none;
  }

  .Header__logged-in {
    padding-right: 10px;
    padding-top: 10px;
  }
  
  .Header__not-logged-in {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
  }
  
  .Header__not-logged-in a:last-child {
    background: #FF8D2E;
    color: white;
    padding: 5px 15px;
    border-radius: 15px;
    margin-left: 10px;
  }
  
  .Header__logged-in a, .center-nav a {
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 5px 15px;
    border-radius: 15px;
  }
  
  @media screen and (min-width: 490px) {
    
    .Header_image {
      height: 54px;
      margin-top: 3px;
    }

    .center-nav {
      padding-top: 20px;
      margin-right: 65px;
    }
    .Header__logged-in {
      padding-top: 20px;
    }
    
  }
  